import React, { useState } from "react"
import Head from "../../components/head"
import {
  Card,
  Typography,
  CardActions,
  Button,
} from "@material-ui/core"
import { styled } from '@material-ui/core/styles'
import Layout from "../../components/layout"
import PriceCards from "../../components/priceCards"
import minithumb from '../../images/yf_sl_sns_sm.jpg'
import Footer from "../../components/footer"
import Header from "../../components/header"
import YouTubeIcon from '@material-ui/icons/YouTube'

const PriceCardWrap = styled(Card)({
});
const PaymentDisc = styled(Typography)({
  maxWidth: '400px',
  margin: '16px auto 0 auto',
  lineHeight: '1.8',
  textAlign: 'justify'
});
const PaymentDiscSmall = styled(Typography)({
  maxWidth: '400px',
  margin: '8px auto 16px auto',
  lineHeight: '1.8',
  textAlign: 'justify',
  fontSize:'.8rem'
});
const Payment = () => {
  return (
    <Layout>
      <Head />
      <Header/>
      <div>準備中</div>
      {/*
      <div>
          <img src={minithumb} className="thumbmini" />
            <div className="streamLinkButtonWrap">
          <a href="https://youtu.be/L3o7xNNz9js" target="_blank" rel="noreferrer noopener" className="streamLinkButton"><Button><YouTubeIcon/>配信リンク</Button></a>
        </div>
            <PaymentDisc>
          お支払いは各種クレジットカード、Apple Pay、Google Payに対応しています。本サービスは決済システムに<a href="https://stripe.com/jp"  target="_blank" rel="noreferrer noopener">stripe</a>を使用しています。クレジットカードの情報は当サービスで保持、管理することはありません。また個人情報保護方針については<a href="https://yomogi-design.site/privacy-policy/"  target="_blank" rel="noreferrer noopener">こちら</a>を特定商取引に関する表記については<a href="https://yomogi-design.site/transaction/"  target="_blank" rel="noreferrer noopener">こちら</a>をご覧ください。
        </PaymentDisc>
        <PriceCardWrap style={{marginTop:'2rem'}}>
          <PriceCards price={'100'} productId={"price_1IQNcYHIZ4uqRrjVRcmqS5un"} amountCount={10} pageName={'yfsl0306'}/>
        </PriceCardWrap>
        <PriceCardWrap style={{marginTop:'2rem'}}>
          <PriceCards price={'500'} productId={"price_1IQNcYHIZ4uqRrjVt99EgHlI"} amountCount={10} pageName={'yfsl0306'}/>
        </PriceCardWrap>
        <PriceCardWrap style={{marginTop:'2rem'}}>
          <PriceCards price={'1,000'} productId={"price_1IQNcYHIZ4uqRrjV0peblJKh"} amountCount={10} pageName={'yfsl0306'}/>
        </PriceCardWrap>
        <PriceCardWrap style={{marginTop:'2rem'}}>
          <PriceCards price={'5,000'} productId={"price_1IQNcYHIZ4uqRrjVRkjoARS1"} amountCount={2} pageName={'yfsl0306'}/>
        </PriceCardWrap>
        <PriceCardWrap style={{marginTop:'2rem'}}>
          <PriceCards price={'10,000'} productId={"price_1IQNcYHIZ4uqRrjVVEFrI9fR"} amountCount={1} pageName={'yfsl0306'}/>
        </PriceCardWrap>
        
      </div>
      */}
      <Footer/>
    </Layout>
  )
}

export default Payment
